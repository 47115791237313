import React from "react";
import classnames from "classnames";
import Logo from "./logo";

function App() {
  return (
    <div className="flex items-center justify-center h-full w-full overflow-hidden">
      <div
        className={classnames(
          "flex items-center flex-col justify-center text-xl center text-black bg-white rounded-full aspect-square p-8 transition-all duration-1000 rounded-full w-full h-full max-w-screen-lg"
        )}
        style={{ fontFamily: "Playfair Display", aspectRatio: "1/1" }}
      >
        <div className="pb-16">
          <Logo />
        </div>
        <div className="flex flex-row">
          <div className="pr-8">
            <p className="p-4">
              Each year, over almost{" "}
              <span className="font-black text-[#0085ff] text-2xl">
                7.9 billion
              </span>{" "}
              units of rigid plastic are created just for beauty and personal
              care products in the US alone.
            </p>
            <p className="p-4">
              <span className="font-black text-[#0085ff] text-2xl">
                Unfortunately, plastic recycling is a myth.
              </span>{" "}
              The <i>most</i> recyclable plastics can only be recycled a handful
              of times before they become too structurally weak to be recycled
              again.
            </p>
            <p className="p-4">
              Counterculture creates products you'll love made from{" "}
              <span className="font-black text-[#0085ff] text-2xl">
                100% infinitely recyclable
              </span>{" "}
              materials the ocean loves, too.
            </p>
          </div>
          <img src="/purple.png" alt="smashed purple makeup" />
        </div>
      </div>
    </div>
  );
}

export default App;
