import React from "react";

function Logo() {
  return (
    <svg
      width="528"
      height="49"
      viewBox="0 0 528 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6_57"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="524"
        height="48"
      >
        <path
          d="M506.344 45V36.66H527.524V45H506.344ZM506.344 11.34V3H527.524V11.34H506.344ZM506.344 27V18.84H526.324V27H506.344ZM499.624 3H509.344V45H499.624V3Z"
          style={{ maskType: "alpha" }}
          fill="black"
        />
        <path
          d="M471.276 25.98H481.776L494.916 45H483.036L471.276 25.98ZM460.776 3H470.916V45H460.776V3ZM467.136 11.58V3H476.076C479.676 3 482.676 3.58 485.076 4.74C487.476 5.9 489.296 7.52 490.536 9.6C491.776 11.64 492.396 14.02 492.396 16.74C492.396 19.42 491.776 21.8 490.536 23.88C489.296 25.92 487.476 27.52 485.076 28.68C482.676 29.84 479.676 30.42 476.076 30.42H467.136V22.56H475.356C476.716 22.56 477.876 22.36 478.836 21.96C479.836 21.52 480.596 20.9 481.116 20.1C481.636 19.26 481.896 18.26 481.896 17.1C481.896 15.94 481.636 14.96 481.116 14.16C480.596 13.32 479.836 12.68 478.836 12.24C477.876 11.8 476.716 11.58 475.356 11.58H467.136Z"
          fill="black"
        />
        <path
          d="M418.406 3H428.546V30.48C428.546 32.6 429.106 34.32 430.226 35.64C431.346 36.96 433.026 37.62 435.266 37.62C437.546 37.62 439.246 36.96 440.366 35.64C441.486 34.32 442.046 32.6 442.046 30.48V3H452.186V31.2C452.186 33.84 451.746 36.14 450.866 38.1C450.026 40.06 448.826 41.68 447.266 42.96C445.746 44.2 443.946 45.14 441.866 45.78C439.826 46.42 437.626 46.74 435.266 46.74C432.906 46.74 430.706 46.42 428.666 45.78C426.626 45.14 424.826 44.2 423.266 42.96C421.746 41.68 420.546 40.06 419.666 38.1C418.826 36.14 418.406 33.84 418.406 31.2V3Z"
          fill="black"
        />
        <path
          d="M380.873 12.06V3H414.113V12.06H402.533V45H392.393V12.06H380.873Z"
          fill="black"
        />
        <path d="M357.592 3H367.732V36.3H384.592V45H357.592V3Z" fill="black" />
        <path
          d="M315.222 3H325.362V30.48C325.362 32.6 325.922 34.32 327.042 35.64C328.162 36.96 329.842 37.62 332.082 37.62C334.362 37.62 336.062 36.96 337.182 35.64C338.302 34.32 338.862 32.6 338.862 30.48V3H349.002V31.2C349.002 33.84 348.562 36.14 347.682 38.1C346.842 40.06 345.642 41.68 344.082 42.96C342.562 44.2 340.762 45.14 338.682 45.78C336.642 46.42 334.442 46.74 332.082 46.74C329.722 46.74 327.522 46.42 325.482 45.78C323.442 45.14 321.642 44.2 320.082 42.96C318.562 41.68 317.362 40.06 316.482 38.1C315.642 36.14 315.222 33.84 315.222 31.2V3Z"
          fill="black"
        />
        <path
          d="M282.092 24C282.092 26.56 282.672 28.78 283.832 30.66C284.992 32.5 286.512 33.9 288.392 34.86C290.312 35.82 292.412 36.3 294.692 36.3C296.692 36.3 298.452 36.04 299.972 35.52C301.492 35 302.852 34.32 304.052 33.48C305.252 32.64 306.312 31.74 307.232 30.78V41.7C305.552 43.02 303.712 44.06 301.712 44.82C299.712 45.58 297.172 45.96 294.092 45.96C290.732 45.96 287.632 45.44 284.792 44.4C281.952 43.36 279.512 41.86 277.472 39.9C275.432 37.94 273.852 35.62 272.732 32.94C271.612 30.26 271.052 27.28 271.052 24C271.052 20.72 271.612 17.74 272.732 15.06C273.852 12.38 275.432 10.06 277.472 8.09998C279.512 6.13998 281.952 4.63998 284.792 3.59998C287.632 2.55998 290.732 2.03998 294.092 2.03998C297.172 2.03998 299.712 2.41998 301.712 3.17998C303.712 3.93998 305.552 4.97998 307.232 6.29998V17.22C306.312 16.26 305.252 15.36 304.052 14.52C302.852 13.64 301.492 12.96 299.972 12.48C298.452 11.96 296.692 11.7 294.692 11.7C292.412 11.7 290.312 12.18 288.392 13.14C286.512 14.1 284.992 15.5 283.832 17.34C282.672 19.18 282.092 21.4 282.092 24Z"
          fill="black"
        />
        <path
          d="M247.738 24.3H253.438L268.438 45H262.138L247.738 24.3ZM240.238 3H245.338V45H240.238V3ZM243.058 7.5V3H251.638C254.358 3 256.778 3.52 258.898 4.56C261.058 5.56 262.758 7 263.998 8.88C265.278 10.76 265.918 13 265.918 15.6C265.918 18.16 265.278 20.4 263.998 22.32C262.758 24.2 261.058 25.66 258.898 26.7C256.778 27.7 254.358 28.2 251.638 28.2H243.058V23.7H251.638C253.438 23.7 255.018 23.38 256.378 22.74C257.778 22.1 258.858 21.18 259.618 19.98C260.418 18.78 260.818 17.32 260.818 15.6C260.818 13.88 260.418 12.42 259.618 11.22C258.858 10.02 257.778 9.1 256.378 8.46C255.018 7.82 253.438 7.5 251.638 7.5H243.058Z"
          fill="black"
        />
        <path
          d="M210.069 45V40.2H231.249V45H210.069ZM210.069 7.8V3H231.249V7.8H210.069ZM210.069 24.6V19.8H230.049V24.6H210.069ZM207.249 3H212.349V45H207.249V3Z"
          fill="black"
        />
        <path
          d="M175.159 7.8V3H201.859V7.8H191.059V45H185.959V7.8H175.159Z"
          fill="black"
        />
        <path
          d="M164.635 2.99999H169.735V47.1L137.935 13.74V45H132.835V0.899994L164.635 34.26V2.99999Z"
          fill="black"
        />
        <path
          d="M94.9766 3H100.077V31.2C100.077 34.08 100.857 36.4 102.417 38.16C103.977 39.92 106.257 40.8 109.257 40.8C112.257 40.8 114.537 39.92 116.097 38.16C117.657 36.4 118.437 34.08 118.437 31.2V3H123.537V31.2C123.537 33.28 123.197 35.2 122.517 36.96C121.877 38.72 120.917 40.24 119.637 41.52C118.397 42.8 116.897 43.8 115.137 44.52C113.377 45.24 111.417 45.6 109.257 45.6C107.097 45.6 105.137 45.24 103.377 44.52C101.617 43.8 100.097 42.8 98.8166 41.52C97.5766 40.24 96.6166 38.72 95.9366 36.96C95.2966 35.2 94.9766 33.28 94.9766 31.2V3Z"
          fill="black"
        />
        <path
          d="M50.8742 24C50.8742 27.16 51.5542 29.98 52.9142 32.46C54.3142 34.94 56.2142 36.9 58.6142 38.34C61.0142 39.78 63.7342 40.5 66.7742 40.5C69.8542 40.5 72.5742 39.78 74.9342 38.34C77.3342 36.9 79.2142 34.94 80.5742 32.46C81.9742 29.98 82.6742 27.16 82.6742 24C82.6742 20.84 81.9742 18.02 80.5742 15.54C79.2142 13.06 77.3342 11.1 74.9342 9.65999C72.5742 8.22 69.8542 7.5 66.7742 7.5C63.7342 7.5 61.0142 8.22 58.6142 9.65999C56.2142 11.1 54.3142 13.06 52.9142 15.54C51.5542 18.02 50.8742 20.84 50.8742 24ZM45.4742 24C45.4742 20.92 45.9942 18.08 47.0342 15.48C48.1142 12.84 49.6142 10.56 51.5342 8.64C53.4542 6.67999 55.7142 5.15999 58.3142 4.07999C60.9142 2.95999 63.7342 2.39999 66.7742 2.39999C69.8542 2.39999 72.6742 2.95999 75.2342 4.07999C77.8342 5.15999 80.0942 6.67999 82.0142 8.64C83.9342 10.56 85.4142 12.84 86.4542 15.48C87.5342 18.08 88.0742 20.92 88.0742 24C88.0742 27.04 87.5342 29.88 86.4542 32.52C85.4142 35.16 83.9342 37.46 82.0142 39.42C80.0942 41.34 77.8342 42.86 75.2342 43.98C72.6742 45.06 69.8542 45.6 66.7742 45.6C63.7342 45.6 60.9142 45.06 58.3142 43.98C55.7142 42.86 53.4542 41.34 51.5342 39.42C49.6142 37.46 48.1142 35.16 47.0342 32.52C45.9942 29.88 45.4742 27.04 45.4742 24Z"
          fill="black"
        />
        <path
          d="M9.80002 24C9.80002 27.24 10.52 30.1 11.96 32.58C13.44 35.06 15.36 37 17.72 38.4C20.08 39.8 22.64 40.5 25.4 40.5C27.4 40.5 29.22 40.24 30.86 39.72C32.54 39.2 34.06 38.48 35.42 37.56C36.78 36.6 37.94 35.5 38.9 34.26V40.98C37.14 42.54 35.22 43.7 33.14 44.46C31.06 45.22 28.48 45.6 25.4 45.6C22.48 45.6 19.74 45.08 17.18 44.04C14.66 42.96 12.44 41.46 10.52 39.54C8.60002 37.58 7.10002 35.28 6.02002 32.64C4.94002 30 4.40002 27.12 4.40002 24C4.40002 20.88 4.94002 18 6.02002 15.36C7.10002 12.72 8.60002 10.44 10.52 8.51999C12.44 6.55999 14.66 5.06 17.18 4.01999C19.74 2.93999 22.48 2.39999 25.4 2.39999C28.48 2.39999 31.06 2.77999 33.14 3.53999C35.22 4.29999 37.14 5.45999 38.9 7.01999V13.74C37.94 12.5 36.78 11.42 35.42 10.5C34.06 9.53999 32.54 8.79999 30.86 8.27999C29.22 7.75999 27.4 7.5 25.4 7.5C22.64 7.5 20.08 8.2 17.72 9.59999C15.36 11 13.44 12.94 11.96 15.42C10.52 17.86 9.80002 20.72 9.80002 24Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_6_57)">
        <path d="M-1 47V38L528 16.5V47H-1Z" fill="#161616" />
      </g>
      <mask
        id="mask1_6_57"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="524"
        height="48"
      >
        <path
          d="M502.344 46V37.66H523.524V46H502.344ZM502.344 12.34V4H523.524V12.34H502.344ZM502.344 28V19.84H522.324V28H502.344ZM495.624 4H505.344V46H495.624V4Z"
          fill="black"
        />
        <path
          d="M467.276 26.98H477.776L490.916 46H479.036L467.276 26.98ZM456.776 4H466.916V46H456.776V4ZM463.136 12.58V4H472.076C475.676 4 478.676 4.58 481.076 5.74C483.476 6.9 485.296 8.52 486.536 10.6C487.776 12.64 488.396 15.02 488.396 17.74C488.396 20.42 487.776 22.8 486.536 24.88C485.296 26.92 483.476 28.52 481.076 29.68C478.676 30.84 475.676 31.42 472.076 31.42H463.136V23.56H471.356C472.716 23.56 473.876 23.36 474.836 22.96C475.836 22.52 476.596 21.9 477.116 21.1C477.636 20.26 477.896 19.26 477.896 18.1C477.896 16.94 477.636 15.96 477.116 15.16C476.596 14.32 475.836 13.68 474.836 13.24C473.876 12.8 472.716 12.58 471.356 12.58H463.136Z"
          fill="black"
        />
        <path
          d="M414.406 4H424.546V31.48C424.546 33.6 425.106 35.32 426.226 36.64C427.346 37.96 429.026 38.62 431.266 38.62C433.546 38.62 435.246 37.96 436.366 36.64C437.486 35.32 438.046 33.6 438.046 31.48V4H448.186V32.2C448.186 34.84 447.746 37.14 446.866 39.1C446.026 41.06 444.826 42.68 443.266 43.96C441.746 45.2 439.946 46.14 437.866 46.78C435.826 47.42 433.626 47.74 431.266 47.74C428.906 47.74 426.706 47.42 424.666 46.78C422.626 46.14 420.826 45.2 419.266 43.96C417.746 42.68 416.546 41.06 415.666 39.1C414.826 37.14 414.406 34.84 414.406 32.2V4Z"
          fill="black"
        />
        <path
          d="M376.873 13.06V4H410.113V13.06H398.533V46H388.393V13.06H376.873Z"
          fill="black"
        />
        <path d="M353.592 4H363.732V37.3H380.592V46H353.592V4Z" fill="black" />
        <path
          d="M311.222 4H321.362V31.48C321.362 33.6 321.922 35.32 323.042 36.64C324.162 37.96 325.842 38.62 328.082 38.62C330.362 38.62 332.062 37.96 333.182 36.64C334.302 35.32 334.862 33.6 334.862 31.48V4H345.002V32.2C345.002 34.84 344.562 37.14 343.682 39.1C342.842 41.06 341.642 42.68 340.082 43.96C338.562 45.2 336.762 46.14 334.682 46.78C332.642 47.42 330.442 47.74 328.082 47.74C325.722 47.74 323.522 47.42 321.482 46.78C319.442 46.14 317.642 45.2 316.082 43.96C314.562 42.68 313.362 41.06 312.482 39.1C311.642 37.14 311.222 34.84 311.222 32.2V4Z"
          fill="black"
        />
        <path
          d="M278.092 25C278.092 27.56 278.672 29.78 279.832 31.66C280.992 33.5 282.512 34.9 284.392 35.86C286.312 36.82 288.412 37.3 290.692 37.3C292.692 37.3 294.452 37.04 295.972 36.52C297.492 36 298.852 35.32 300.052 34.48C301.252 33.64 302.312 32.74 303.232 31.78V42.7C301.552 44.02 299.712 45.06 297.712 45.82C295.712 46.58 293.172 46.96 290.092 46.96C286.732 46.96 283.632 46.44 280.792 45.4C277.952 44.36 275.512 42.86 273.472 40.9C271.432 38.94 269.852 36.62 268.732 33.94C267.612 31.26 267.052 28.28 267.052 25C267.052 21.72 267.612 18.74 268.732 16.06C269.852 13.38 271.432 11.06 273.472 9.09998C275.512 7.13998 277.952 5.63998 280.792 4.59998C283.632 3.55998 286.732 3.03998 290.092 3.03998C293.172 3.03998 295.712 3.41998 297.712 4.17998C299.712 4.93998 301.552 5.97998 303.232 7.29998V18.22C302.312 17.26 301.252 16.36 300.052 15.52C298.852 14.64 297.492 13.96 295.972 13.48C294.452 12.96 292.692 12.7 290.692 12.7C288.412 12.7 286.312 13.18 284.392 14.14C282.512 15.1 280.992 16.5 279.832 18.34C278.672 20.18 278.092 22.4 278.092 25Z"
          fill="black"
        />
        <path
          d="M243.738 25.3H249.438L264.438 46H258.138L243.738 25.3ZM236.238 4H241.338V46H236.238V4ZM239.058 8.5V4H247.638C250.358 4 252.778 4.52 254.898 5.56C257.058 6.56 258.758 8 259.998 9.88C261.278 11.76 261.918 14 261.918 16.6C261.918 19.16 261.278 21.4 259.998 23.32C258.758 25.2 257.058 26.66 254.898 27.7C252.778 28.7 250.358 29.2 247.638 29.2H239.058V24.7H247.638C249.438 24.7 251.018 24.38 252.378 23.74C253.778 23.1 254.858 22.18 255.618 20.98C256.418 19.78 256.818 18.32 256.818 16.6C256.818 14.88 256.418 13.42 255.618 12.22C254.858 11.02 253.778 10.1 252.378 9.46C251.018 8.82 249.438 8.5 247.638 8.5H239.058Z"
          fill="black"
        />
        <path
          d="M206.069 46V41.2H227.249V46H206.069ZM206.069 8.8V4H227.249V8.8H206.069ZM206.069 25.6V20.8H226.049V25.6H206.069ZM203.249 4H208.349V46H203.249V4Z"
          fill="black"
        />
        <path
          d="M171.159 8.8V4H197.859V8.8H187.059V46H181.959V8.8H171.159Z"
          fill="black"
        />
        <path
          d="M160.635 3.99999H165.735V48.1L133.935 14.74V46H128.835V1.89999L160.635 35.26V3.99999Z"
          fill="black"
        />
        <path
          d="M90.9766 4H96.0766V32.2C96.0766 35.08 96.8566 37.4 98.4166 39.16C99.9766 40.92 102.257 41.8 105.257 41.8C108.257 41.8 110.537 40.92 112.097 39.16C113.657 37.4 114.437 35.08 114.437 32.2V4H119.537V32.2C119.537 34.28 119.197 36.2 118.517 37.96C117.877 39.72 116.917 41.24 115.637 42.52C114.397 43.8 112.897 44.8 111.137 45.52C109.377 46.24 107.417 46.6 105.257 46.6C103.097 46.6 101.137 46.24 99.3766 45.52C97.6166 44.8 96.0966 43.8 94.8166 42.52C93.5766 41.24 92.6166 39.72 91.9366 37.96C91.2966 36.2 90.9766 34.28 90.9766 32.2V4Z"
          fill="black"
        />
        <path
          d="M46.8742 25C46.8742 28.16 47.5542 30.98 48.9142 33.46C50.3142 35.94 52.2142 37.9 54.6142 39.34C57.0142 40.78 59.7342 41.5 62.7742 41.5C65.8542 41.5 68.5742 40.78 70.9342 39.34C73.3342 37.9 75.2142 35.94 76.5742 33.46C77.9742 30.98 78.6742 28.16 78.6742 25C78.6742 21.84 77.9742 19.02 76.5742 16.54C75.2142 14.06 73.3342 12.1 70.9342 10.66C68.5742 9.22 65.8542 8.5 62.7742 8.5C59.7342 8.5 57.0142 9.22 54.6142 10.66C52.2142 12.1 50.3142 14.06 48.9142 16.54C47.5542 19.02 46.8742 21.84 46.8742 25ZM41.4742 25C41.4742 21.92 41.9942 19.08 43.0342 16.48C44.1142 13.84 45.6142 11.56 47.5342 9.64C49.4542 7.67999 51.7142 6.15999 54.3142 5.07999C56.9142 3.95999 59.7342 3.39999 62.7742 3.39999C65.8542 3.39999 68.6742 3.95999 71.2342 5.07999C73.8342 6.15999 76.0942 7.67999 78.0142 9.64C79.9342 11.56 81.4142 13.84 82.4542 16.48C83.5342 19.08 84.0742 21.92 84.0742 25C84.0742 28.04 83.5342 30.88 82.4542 33.52C81.4142 36.16 79.9342 38.46 78.0142 40.42C76.0942 42.34 73.8342 43.86 71.2342 44.98C68.6742 46.06 65.8542 46.6 62.7742 46.6C59.7342 46.6 56.9142 46.06 54.3142 44.98C51.7142 43.86 49.4542 42.34 47.5342 40.42C45.6142 38.46 44.1142 36.16 43.0342 33.52C41.9942 30.88 41.4742 28.04 41.4742 25Z"
          fill="black"
        />
        <path
          d="M5.80002 25C5.80002 28.24 6.52003 31.1 7.96003 33.58C9.44003 36.06 11.36 38 13.72 39.4C16.08 40.8 18.64 41.5 21.4 41.5C23.4 41.5 25.22 41.24 26.86 40.72C28.54 40.2 30.06 39.48 31.42 38.56C32.78 37.6 33.94 36.5 34.9 35.26V41.98C33.14 43.54 31.22 44.7 29.14 45.46C27.06 46.22 24.48 46.6 21.4 46.6C18.48 46.6 15.74 46.08 13.18 45.04C10.66 43.96 8.44003 42.46 6.52002 40.54C4.60002 38.58 3.10002 36.28 2.02002 33.64C0.940024 31 0.400024 28.12 0.400024 25C0.400024 21.88 0.940024 19 2.02002 16.36C3.10002 13.72 4.60002 11.44 6.52002 9.51999C8.44003 7.55999 10.66 6.06 13.18 5.01999C15.74 3.93999 18.48 3.39999 21.4 3.39999C24.48 3.39999 27.06 3.77999 29.14 4.53999C31.22 5.29999 33.14 6.45999 34.9 8.01999V14.74C33.94 13.5 32.78 12.42 31.42 11.5C30.06 10.54 28.54 9.79999 26.86 9.27999C25.22 8.75999 23.4 8.5 21.4 8.5C18.64 8.5 16.08 9.2 13.72 10.6C11.36 12 9.44003 13.94 7.96003 16.42C6.52003 18.86 5.80002 21.72 5.80002 25Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1_6_57)">
        <path d="M0 -7H528V13L0 34V-7Z" fill="#222222" />
      </g>
    </svg>
  );
}

export default Logo;
